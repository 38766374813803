function copyUrl(event) {
  event.preventDefault();

  const url = window.location.href;
  navigator.clipboard.writeText(url);

  event.target.title = 'Ссылка скопирована'
  
  const tooltip = new bootstrap.Tooltip(event.target, {})
  tooltip.show();
}

function copyButtonEventListener(className, event, fn) {
  const copyButton = document.querySelector(className);
  if (copyButton !== null) { copyButton.addEventListener(event, fn, false); }
}

document.addEventListener("DOMContentLoaded", function() {
  copyButtonEventListener('#js-share-copy', 'click', copyUrl);
})
